var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { toastMessages, useAddToast } from "@pm-frontend/shared/store/toast";
import { apiFetch, apiPatch, apiPost, apiPut } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { meldKeys } from "@pm-frontend/routes/Melds/queries";
const estimateKeys = {
  all: ["estimates"],
  create: (meld_id) => [...estimateKeys.all, "meld", meld_id.toString()],
  detail: (id) => [...estimateKeys.all, id.toString()],
  lineItems: (id) => [...estimateKeys.detail(id), "line-items"],
  markupDefaults: () => [...estimateKeys.all, "line-items", "markup", "defaults"]
};
const useCreateEstimate = (meld_id, flyoutToggle) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  const lineitemMutation = useCreateEstimateLineItems();
  return useMutation({
    mutationFn: (data) => {
      const _a = data, { lineitems } = _a, estimate = __objRest(_a, ["lineitems"]);
      return apiPost(LinkHelper.normalize(ApiUrls.meldEstimates(meld_id)), estimate);
    },
    onSuccess: (data, variables) => __async(void 0, null, function* () {
      const lineItemArgs = variables.lineitems.map((lineitem) => ({ estimate_id: data.id, data: lineitem }));
      yield Promise.all(lineItemArgs.map((args) => lineitemMutation.mutate(args)));
      if (flyoutToggle) {
        flyoutToggle.close();
      }
      queryClient.invalidateQueries({ queryKey: meldKeys.detail(meld_id) });
      addToast({
        text: toastMessages.estimateCreationSuccess,
        color: "success"
      });
    }),
    onError: () => {
      addToast({
        text: toastMessages.estimateCreationFailure,
        color: "danger"
      });
    }
  });
};
const useCreateEstimateLineItems = () => {
  const addToast = useAddToast();
  return useMutation({
    mutationFn: ({ estimate_id, data }) => apiPost(LinkHelper.normalize(ApiUrls.estimateLineItems(estimate_id)), __spreadProps(__spreadValues({}, data), { cost_estimate: estimate_id })),
    onError: () => {
      addToast({
        text: toastMessages.estimateLineItemCreationFailure,
        color: "danger"
      });
    }
  });
};
const useUpdateEstimate = (estimateId) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: (data) => {
      const _a = data, { lineitems } = _a, otherData = __objRest(_a, ["lineitems"]);
      const formData = __spreadProps(__spreadValues({}, otherData), {
        line_items: lineitems.map((item) => "cost_estimate" in item ? item : __spreadProps(__spreadValues({}, item), { cost_estimate: estimateId }))
      });
      return apiPut(LinkHelper.normalize(ApiUrls.meldEstimatesV2(estimateId)), formData);
    },
    onSuccess: (data) => __async(void 0, null, function* () {
      queryClient.invalidateQueries({ queryKey: meldKeys.detail(data.original_meld) });
      queryClient.invalidateQueries({ queryKey: estimateKeys.detail(estimateId) });
      queryClient.invalidateQueries({ queryKey: estimateKeys.lineItems(estimateId) });
      addToast({
        text: toastMessages.estimateUpdateSuccess,
        color: "success"
      });
    }),
    onError: () => {
      addToast({
        text: toastMessages.estimateUpdateFailure,
        color: "danger"
      });
    }
  });
};
const useUpdateEstimateLineItems = (estimateId) => {
  const addToast = useAddToast();
  return useMutation({
    mutationFn: ({ data }) => {
      if (data.id) {
        const _a = data, { id } = _a, lineitem = __objRest(_a, ["id"]);
        return apiPut(LinkHelper.normalize(ApiUrls.estimateLineItem(id)), lineitem);
      } else {
        return apiPost(LinkHelper.normalize(ApiUrls.estimateLineItems(estimateId)), data);
      }
    },
    onError: () => {
      addToast({
        text: toastMessages.estimateLineItemUpdateFailure,
        color: "danger"
      });
    }
  });
};
const useGetEstimateLineItems = (estimateId) => {
  return useQuery({
    queryKey: estimateKeys.lineItems(estimateId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.estimateLineItems(estimateId)))
  });
};
const useApproveMeldEstimate = (estimateId, meldId) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: () => apiPatch(LinkHelper.normalize(ApiUrls.estimateDetail(estimateId.toString())), {
      estimate_status: "ESTIMATE_APPROVED"
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldApproveEstimateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldApproveEstimateFailure,
        color: "danger"
      });
    }
  });
};
const useCancelMeldEstimate = (estimateId, meldId) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: () => apiPatch(LinkHelper.normalize(ApiUrls.estimateDetail(estimateId.toString())), {
      estimate_status: "ESTIMATE_CLOSED"
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldCloseEstimateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldCloseEstimateFailure,
        color: "danger"
      });
    }
  });
};
const useBulkRequestOwnerApprovalMeldEstimate = (estimateId, meldId) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  const defaultSelectedFiles = {
    managerFiles: [],
    vendorFiles: [],
    tenantFiles: []
  };
  return useMutation({
    mutationFn: ({
      showLineItems,
      files = defaultSelectedFiles,
      excludedColumns = []
    }) => apiPost(LinkHelper.normalize(ApiUrls.estimateRequestOwnerApproval), __spreadValues({
      approvalEstimates: [{ id: estimateId }],
      showLineItems,
      excludedHeaders: excludedColumns.join(",")
    }, files)),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateFailure,
        color: "danger"
      });
    }
  });
};
const useRequestOwnerApprovalMeldEstimate = (estimateId, meldId) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: (showLineItems) => apiPost(LinkHelper.normalize(ApiUrls.estimateRequestOwnerApproval), {
      approvalEstimates: [{ id: estimateId }],
      showLineItems
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateFailure,
        color: "danger"
      });
    }
  });
};
const useCancelRequestOwnerApprovalMeldEstimate = (estimateId, meldId) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: () => apiPatch(LinkHelper.normalize(ApiUrls.estimateDetail(estimateId)), {
      estimate_status: "ESTIMATE_SUBMITTED"
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldCancelRequestOwnerApprovalEstimateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldCancelRequestOwnerApprovalEstimateFailure,
        color: "danger"
      });
    }
  });
};
const useGetMarkupDefaults = () => {
  var _a, _b;
  const query = useQuery({
    queryKey: estimateKeys.markupDefaults(),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.applicationSettings)),
    cacheTime: 0,
    staleTime: 0
  });
  return __spreadProps(__spreadValues({}, query), {
    default_markup: (_a = query.data) == null ? void 0 : _a.default_estimate_line_item_markup,
    default_markup_type: (_b = query.data) == null ? void 0 : _b.default_estimate_line_item_markup_metric
  });
};
const useGetEstimatePDFPreview = (estimateId, excludedHeaders, hideLineItems, managerFiles, tenantFiles, vendorFiles) => {
  return useQuery({
    queryFn: () => apiPost(LinkHelper.normalize(ApiUrls.estimatePDFPreview(estimateId)), {
      excluded_headers: excludedHeaders.join(","),
      show_line_items: !hideLineItems,
      manager_files: managerFiles,
      tenant_files: tenantFiles,
      vendor_files: vendorFiles
    })
  });
};
export {
  useApproveMeldEstimate,
  useCancelMeldEstimate,
  useCreateEstimate,
  useRequestOwnerApprovalMeldEstimate,
  useCancelRequestOwnerApprovalMeldEstimate,
  useGetEstimateLineItems,
  useGetMarkupDefaults,
  useUpdateEstimate,
  useUpdateEstimateLineItems,
  useBulkRequestOwnerApprovalMeldEstimate,
  useGetEstimatePDFPreview,
  estimateKeys
};
