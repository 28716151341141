import { LDClient, initialize as LDInit } from "launchdarkly-js-client-sdk";

let client: DummyClient | LDClient | undefined;
let isReady = false;

const Features = {
  isInjected: false,
  isMDSMeldListEnabled() {
    return variation("features.mds-meld-list", false);
  },
  isNewUnitListEnabled() {
    return variation("features.new-unit-list-view", false);
  },
  isEstimatesListViewEnabled() {
    return variation("features.estimates", false);
  },
  isBehaviorsEnabled() {
    return variation("features.behaviors", false);
  },
  isOwnerServicesEnabled() {
    return variation("features.owner-services", false);
  },
  isWhiteLabelingEnabled() {
    return variation("features.white-labeling", false);
  },
  isPageBannerEnabled() {
    return variation("features.page-banner", false);
  },
  isBillingPortalEnabled() {
    return variation("features.billing-portal", false);
  },
  isLegacyCustomer() {
    return variation("entitlements.legacy-customers", false);
  },
  isWorkflowUpsellEnabled() {
    return variation("features.workflow-upsells", false);
  },
  isPermissionToEnterEnabled() {
    return variation("features.permission-to-enter", false);
  },
  isBusinessHoursExperimentEnabled() {
    return variation("experiments.business-hours", false);
  },
  isBuildiumOnboardingProcess() {
    return variation("features.buildium-integration.onboarding", "MELD_EXISTS");
  },
  isPropertyNameEnabled() {
    return variation("features.property-name", false);
  },
  isUIRedesignEnabledProduction() {
    return variation("features.ui-redesign-2023", false) === "PRODUCTION";
  },
  isUIRedesignEnabledBeta() {
    return variation("features.ui-redesign-2023", false) === "BETA";
  },
  isUIRedesignEnabledDevelopment() {
    return variation("features.ui-redesign-2023", false) === "DEVELOPMENT";
  },
  isUIRedesignEnabledDevelopmentOrProduction() {
    return Features.isUIRedesignEnabledProduction() || Features.isUIRedesignEnabledDevelopment();
  },
  isUIRedesignSunset() {
    return variation("features.ui-redesign-2023-sunset", false);
  },
  isUIRedesignScheduler() {
    const value = variation("ui-redesign-scheduler-2-0", "DISABLED");
    return value.startsWith("ENABLED") || value.startsWith("SUNSET");
  },
  isUIRedesignSchedulerSunset() {
    return variation("ui-redesign-scheduler-2-0", "DISABLED").startsWith("SUNSET");
  },
  isUIRedesignSchedulerNearbyEnabled() {
    return variation("ui-redesign-scheduler-2-0", "DISABLED").endsWith("NEARBY");
  },
  isUIRedesignSchedulerMobile() {
    const value = variation("features.ui-redesign-scheduler-mobile-2", "DISABLED");
    // this flag is dependent on the overall scheduler flag
    return Features.isUIRedesignScheduler() && (value === "ENABLED" || value === "SUNSET");
  },
  isUIRedesignSchedulerMobileSunset() {
    return (
      Features.isUIRedesignScheduler() && variation("features.ui-redesign-scheduler-mobile-2", "DISABLED") === "SUNSET"
    );
  },
  isDraftSchedulerEnabled() {
    return variation("features.meld-calendar-batch-scheduling", false) === "ENABLED";
  },
  isInRecurringRangeEnabled() {
    return variation("features.workflow.inrecurringrange", false);
  },
  isActionableInsightsEnabled() {
    return variation("features.actionable-insight.multi", "NO_INSIGHTS");
  },
  isGptSummaryEnabled() {
    return variation("GPT.chat-summary", false);
  },
  isGptTranslateEnabled() {
    return variation("GPT.translate", false);
  },
  isResidentGptTranslateEnabled() {
    return variation("GPT.translate-residents", false);
  },
  isGptChatModifyEnabled() {
    return variation("GPT.chat-modify", false);
  },
  isIntegrationLogsEnabled() {
    return variation("Integration-Logs.manager-view", false);
  },
  isEnterpriseHubEnabled() {
    return variation("features.enterprise-hub", false);
  },
  isNearbyMeldsEnabled() {
    return variation("features.nearby-melds", false);
  },
  isQuickbookConnectEnabled() {
    return variation("features.quickbooks-connect", false);
  },
  isMergeMeldEnabled() {
    return variation("features.megre-melds", false);
  },
  isMeldMentionsEnabled() {
    return variation("features.meld-mentions", false);
  },
  isWorkLogBannerEnabled() {
    return variation("features.work-log-banner", false);
  },
  isGoogleCalendarWriteIntegrationEnabled() {
    return variation("features.google-calendar-integration", false);
  },
  isGoogleCalendarReadIntegrationEnabled() {
    return variation("google-calendar-read-integration", false);
  },
  isOutlookCalendarReadIntegrationEnabled() {
    return variation("feature.outlook-calendar-read-integration", false);
  },
  // used to tune app performance via feature flag if we need to
  isRefetchOnWindowFocusEnabled() {
    return variation("performance-tuning-refetch-on-window-focus", false);
  },
  // used to tune app performance via feature flag if we need to
  getQueryStaleTime() {
    return variation("performance-tuning-query-stale-time", 0);
  },
  isVendorSearchEnabled() {
    return variation("features.nexus-vendor-search", false);
  },
  isMultipleAppointmentsEnabled() {
    return variation("multiple-appointments", false);
  },
  isNexusHubEnabled() {
    return variation("features.nexus-hub", false);
  },
  isNexusIntegrationsEnabled() {
    return variation("features.nexus-integrations", false);
  },
  isSmartAssignmentEnabled() {
    return variation("features.smart-assignment", false);
  },
  isReportRedesignEnabled() {
    return variation("features.reports-redesign", false);
  },
  isRecommendAPIEnabled() {
    return variation("features.recommend-api", false);
  },
  isAlternativeEventsEnabled() {
    return variation("features.alternative-events", false);
  },
  isAppThemesEnabled() {
    return variation("features.app-themes", false);
  },
  isCreateMeldFormEnabled() {
    return variation("features.create-meld-form", false);
  },
  isEstimatesV2PrerequestEnabled() {
    return variation("features.estimates-2-0-prerequest", false);
  },
  isNexusChatEnabled() {
    return variation("nexus-chat", false);
  },
  isEstimatesV2Enabled() {
    return variation("features.estimates-2-0", false);
  },
  isEstimatesV2RequestCommentsEnabled() {
    return Features.isEstimatesV2Enabled() && variation("features.estimates-2-0.request.comments", false);
  },
  isMeldDetailV21Enabled() {
    return variation("features.meld-details-2-1");
  },
  isHelpMenuEnabled() {
    return variation("features.help-menu", false);
  },
  isSendFromTemplateActionSenderEnabled() {
    return variation("features.send-message-action-sender", false);
  },
  isMaxCreateMeld() {
    return variation("features.max-create-meld", false);
  },
  isWorkTypesEnabled() {
    return variation("features.work-types", false);
  },
  isMeldDetailHeaderRedesign2025Enabled() {
    return variation("features.meld-detail-header-redesign-2025", false);
  },
  isMeldCalendarMeldListEndpointV2Enabled() {
    return variation("meld-calendar-meld-list-endpoint-v-2", false);
  },
  isNexusBillingEnabled() {
    return variation("features.nexus-billing", false);
  },
  isNexusDashboardEnabled() {
    return variation("features.nexus-hub-dashboard", false);
  },
  isNexusServicePartnersEnabled() {
    return variation("features.nexus-service-partners", false);
  },
  isAppfolioGLCodesForExpenditureLineItemsEnabled() {
    return variation("features.expenditure-line-items-appfolio-glcodes", false);
  },
  isCalendarMobileWebEnabled() {
    return variation("features.calendar-mobile-web", false);
  },
};

export class FeatureFlagClientHelper {
  static onClientReady(fn: () => void) {
    // tslint:disable-next-line
    client?.on("ready", fn);
  }

  static onClientChange(fn: () => void) {
    // tslint:disable-next-line
    client?.on("change", fn); // tslint:disable
  }

  static get isClientReady() {
    return isReady;
  }
}

export function initialize() {
  const featureFlagData = window.PM.featureFlagBootstrap;

  // Use a dummy client instead of Launch Darkly. This is useful for testing purposes where we don't want to connect to Launch Darkly
  if (featureFlagData.bootstrapData.USE_DUMMY_DATA) {
    client = new DummyClient(featureFlagData.bootstrapData);
    isReady = true;
  } else {
    client = LDInit(featureFlagData.clientId, featureFlagData.user, {
      bootstrap: featureFlagData.bootstrapData,
      sendEvents: false,
    });

    client.on("ready", () => {
      isReady = true;
    });

    client.on("change", () => {
      // tslint:disable:no-console
      // eslint-disable-next-line no-console
      console.log("Feature flags changed");
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function variation(variationName: string, defaultValue: any = false) {
  if (client && isReady) {
    return client.variation(variationName, defaultValue);
  }

  return defaultValue;
}

class DummyClient {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly flags: { [name: string]: any };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(flags: { [name: string]: any }) {
    this.flags = flags;
    isReady = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variation(variationName: string, defaultValue: any) {
    return this.flags[variationName] || defaultValue;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(_event: string, _funct: () => void) {
    return; // everything starts ready and this is a Dummy so these events will never fire or have an affect
  }
}

export default Features;
