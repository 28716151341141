/* eslint  @tanstack/query/exhaustive-deps: 0 */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { apiDelete, apiFetch, apiPatch, apiPost } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { AbbreviatedMeldSerializer } from "@pm-frontend/shared/types/api/meld/serializers/serializers";
import { MeldDetailViewSerializerV2 } from "@pm-frontend/shared/types/api/meld/serializers/meld_detail_view_serializer";
import { toastMessages, useAddToast } from "@pm-frontend/shared/store/toast";
import { OwnerApproval, WorkType } from "@pm-frontend/shared/utils/statuses";
import { VendorMeldListViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/vendor_meld_list_view_serializer";
import {
  ManyEstimateListSerializer,
  ManyEstimateSerializer,
} from "@pm-frontend/shared/types/api/estimates/serializers/estimate_serializers";
import { MeldEvent } from "@pm-frontend/shared/types/api/activity/models";
import { WorkEntryListViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/work_entry_list_view_serializer";
import { WriteableTagSerializer } from "@pm-frontend/shared/types/api/meld/serializers/base_serializers";
import { TenantUpdateRetrieveViewSerializer } from "@pm-frontend/shared/types/api/tenant/serializers/tenant_update_retrieve_view_serializer";
import { AxiosError } from "axios";
import { MeldManagerWatchlistViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/meld_manager_watchlist_serializer";
import { ManagementAgent } from "@pm-frontend/shared/types/api/manager/models";
import { MergedMeld } from "@pm-frontend/shared/types/api/meld/models";
import { WorkEntryOpenListViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/work_entry_open_list_view_serializer";
import { estimateKeys } from "@pm-frontend/routes/Estimates/queries";
import { AllVendorsListRegisteredOnlyFalse } from "@pm-frontend/shared/types/api/maintenance/api";
import { calendarMeldKeys } from "../Calendar/queries";
import { MeldDistanceOutputSerializer } from "@pm-frontend/shared/types/api/meld/serializers/meld_location_serializers";
import { NexusDetailSerializer } from "@pm-frontend/shared/types/api/nexus/serializers/nexus_detail_serializer";

const meldKeys = {
  all: ["melds"],
  detail: (id: string | number) => [...meldKeys.all, id.toString()],
  list: (params: string) => [...meldKeys.all, "list", params],
  unitMelds: (id: string | number) => [...meldKeys.all, "unit", id.toString()],
  residentMelds: (id: string) => [...meldKeys.all, "resident", id],
  vendorMelds: (id: string, page: number) => [...meldKeys.all, "vendor", id, "page", page],
  meldAvailableNexus: (meldId: number, postalCode: string | undefined) => [
    ...meldKeys.detail(meldId),
    "nexus-range",
    meldId.toString(),
    postalCode?.toString(),
  ],
  propertyMelds: (id: string) => [...meldKeys.all, "property", id],
  propertyPropertyMelds: (id: string) => [...meldKeys.propertyMelds(id), "propertyLevel"],
  propertyUnitMelds: (id: string) => [...meldKeys.propertyMelds(id), "unitLevel"],
  recurringMelds: (id: string | number) => [...meldKeys.all, "recurring-meld", id.toString()],
  meldEstimates: (id: string | number) => [...meldKeys.detail(id), "estimates"],
  mergedMeld: (id: string | number) => [...meldKeys.detail(id), "merged-meld"],
  activities: (id: string | number) => [...meldKeys.detail(id), "activities"],
  workLogs: (id: string | number) => [...meldKeys.detail(id), "work-logs"],
  meldWorkLog: (meldId: string | number, workEntryId: string | number) => [
    ...meldKeys.detail(meldId),
    "work-logs",
    workEntryId.toString(),
  ],
  currentAgentOpenWorkLog: () => [...meldKeys.all, "current-agent-open-work-logs"],
  searchTags: (query: string) => [...meldKeys.all, "search-tags", query],
  residents: (id: string | number) => [...meldKeys.detail(id), "residents"],
  recommend: (id: string) => [...meldKeys.detail(id), "recommend"],
  managerWatch: (id: string | number) => [...meldKeys.managerWatchlist(id), "manager-watch"],
  managerWatchlist: (id: string | number) => [...meldKeys.detail(id), "manager-watchlist"],
  managerAvailableWatchlist: (
    id: string | number,
    queryParams: { exclude_ids: string; property_group_ids: string }
  ) => [...meldKeys.managerWatchlist(id), queryParams, "manager-available-watchlist"],
  mutations: {
    assign: (id: string) => [...meldKeys.detail(id), "assign"],
    patch: (id: string) => [...meldKeys.detail(id), "patch"],
    createReminder: (id: string) => [...meldKeys.detail(id), "create-reminder"],
    editReminder: (id: string) => [...meldKeys.detail(id), "edit-reminder"],
    createMeld: () => [...meldKeys.all, "create-edit-melds"],
    createRecurringMeld: () => [...meldKeys.all, "create-edit-recurring-melds"],
  },
} as const;

// abbreviatedMeldList
const useGetPropertyLevelMelds = (propertyId: string) => {
  return useQuery<AbbreviatedMeldSerializer[]>({
    queryKey: meldKeys.propertyPropertyMelds(propertyId),
    queryFn: () =>
      apiFetch(`${LinkHelper.normalize(ApiUrls.abbreviatedMeldList)}?prop=${propertyId}&ordering=-created`),
  });
};

const useGetPropertyUnitLevelMelds = (propertyId: string) => {
  return useQuery<AbbreviatedMeldSerializer[]>({
    queryKey: meldKeys.propertyUnitMelds(propertyId),
    queryFn: () =>
      apiFetch(`${LinkHelper.normalize(ApiUrls.abbreviatedMeldList)}?unit_level_melds=${propertyId}&ordering=-created`),
  });
};

const useGetUnitMelds = (unitId: number) => {
  return useQuery<AbbreviatedMeldSerializer[]>({
    queryKey: meldKeys.unitMelds(unitId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.unitMelds(unitId))),
  });
};

const useGetResidentMelds = (residentId: string) => {
  return useQuery<AbbreviatedMeldSerializer[]>({
    queryKey: meldKeys.residentMelds(residentId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.tenantMeldList(residentId))),
  });
};

const useGetMeldDetailsQuery = (meldId: string) => {
  return useQuery<MeldDetailViewSerializerV2>({
    queryKey: meldKeys.detail(meldId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldDetail(meldId))),
    enabled: !!meldId,
  });
};

const useGetMeldResidentDetails = (meldId: number, residentIds: string[]) => {
  return useQuery<TenantUpdateRetrieveViewSerializer[]>({
    queryKey: meldKeys.residents(meldId),
    queryFn: () =>
      Promise.all(residentIds.map((residentId) => apiFetch(LinkHelper.normalize(ApiUrls.tenantDetail(residentId))))),
  });
};

const useGetWorkEntriesMelds = (meldId: string | number) => {
  return useQuery<WorkEntryListViewSerializer[]>({
    queryKey: meldKeys.workLogs(meldId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.workEntryList(meldId))),
    enabled: !!meldId,
    // this query is pre-fetched on the meld details page, staleTime here prevents
    // refetching when the other components using this hook mount
    staleTime: 3000,
  });
};

const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;
const useGetOpenWorkEntries = () => {
  return useQuery<WorkEntryOpenListViewSerializer[]>({
    queryKey: meldKeys.currentAgentOpenWorkLog(),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.workEntryOpenList())),
    // to prevent refetching on page mounts. This query is most often
    // manually invalidated to trigger refreshes
    staleTime: FIVE_MINUTES_IN_MS,
    cacheTime: FIVE_MINUTES_IN_MS,
  });
};

const useGetWorkEntryDetail = (meldId: number | string, workEntryId: number | string) => {
  return useQuery<WorkEntryListViewSerializer>({
    queryKey: meldKeys.meldWorkLog(meldId, workEntryId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.workEntryDetail(workEntryId))),
  });
};

interface EditCoordinatorUpdatePayload {
  id: string;
}

const usePatchCoordinator = (meld: { id: number }) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationFn: (data: EditCoordinatorUpdatePayload) => {
      return apiPatch(LinkHelper.normalize(ApiUrls.meldAssignedCoordinator(meld.id)), { coordinator: data });
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.coordinatorUpdateSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id));
    },
    onError: () => {
      addToast({
        text: toastMessages.coordinatorUpdateFailure,
        color: "danger",
      });
    },
  });
};

interface WorkEntryCreatePayload {
  agent: number;
  description: string;
  long_description?: string;
  checkin: string | null;
  checkout: string | null;
  hours: number;
}

const useCreateWorkEntry = (meld: { id: number }) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationFn: (data: WorkEntryCreatePayload) => {
      return apiPost(LinkHelper.normalize(ApiUrls.workEntryList(meld.id)), {
        ...data,
        meld: meld.id,
      });
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.workLogCreateSuccess,
        color: "success",
      });
      // we are invalidating current open work logs too so the checkout banner will appear or disappear accordingly
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.workLogs(meld.id)),
        queryClient.invalidateQueries(meldKeys.currentAgentOpenWorkLog()),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.workLogCreateFailure,
        color: "danger",
      });
    },
  });
};

interface WorkEntryUpdatePayload extends WorkEntryCreatePayload {
  id: number;
}

const usePatchWorkEntry = (meld: { id: number }) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationFn: (data: WorkEntryUpdatePayload) => {
      return apiPatch(LinkHelper.normalize(ApiUrls.workEntryDetail(data.id)), data);
    },
    onSuccess: (_, requestPayload) => {
      addToast({
        text: toastMessages.workLogUpdateSuccess,
        color: "success",
      });
      // we are invalidating current open work logs too so the checkout banner will appear or disappear accordingly
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.workLogs(meld.id.toString())),
        queryClient.removeQueries(meldKeys.meldWorkLog(meld.id.toString(), requestPayload.id.toString())),
        queryClient.invalidateQueries(meldKeys.currentAgentOpenWorkLog()),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.workLogUpdateFailure,
        color: "danger",
      });
    },
  });
};

interface PatchMeldData {
  maintenance_notes?: string;
  work_type?: WorkType;
}

const usePatchMeldDetails = (meld: { id: number }) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationKey: meldKeys.mutations.patch(meld.id.toString()),
    mutationFn: (data: PatchMeldData) => {
      const newData = {
        ...meld,
        ...data,
      };
      return apiPatch(LinkHelper.normalize(ApiUrls.meldDetail(meld.id)), newData);
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.meldUpdateSuccess,
        color: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.detail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldUpdateFailure,
        color: "danger",
      });
    },
  });
};

interface PatchMeldNotes {
  maintenance_notes?: string;
}
const usePatchMeldDetailsNotes = (meld: { id: number }) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationKey: meldKeys.mutations.patch(meld.id.toString()),
    mutationFn: (data: PatchMeldNotes) => {
      const newData = {
        ...data,
      };
      return apiPatch(LinkHelper.normalize(ApiUrls.meldDetailNotes(meld.id)), newData);
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.meldUpdateSuccess,
        color: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.detail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldUpdateFailure,
        color: "danger",
      });
    },
  });
};

const useGetRecurringMeldMelds = (recurringId: number) => {
  return useQuery<AbbreviatedMeldSerializer[]>({
    queryKey: meldKeys.recurringMelds(recurringId),
    queryFn: () =>
      apiFetch(`${LinkHelper.normalize(ApiUrls.abbreviatedMeldList)}?recurring_meld_related_melds=${recurringId}`),
  });
};

const paginatedVendorMeldUrlBuilder = (vendorId: string, currentPage: number) => {
  const PAGE_SIZE = 10;
  const offset = currentPage * PAGE_SIZE;

  let url = LinkHelper.normalize(ApiUrls.vendorMeldList(vendorId));

  if (currentPage > 0) {
    url = `${url}?limit=${PAGE_SIZE}&offset=${offset}`;
  } else {
    url = `${url}?limit=${PAGE_SIZE}`;
  }

  return url;
};

const useGetMeldNexusesByRange = (meld_id: number, postalCode: string | undefined) => {
  return useQuery<NexusDetailSerializer[]>({
    queryKey: meldKeys.meldAvailableNexus(meld_id, postalCode),
    queryFn: () =>
      apiFetch(LinkHelper.normalize(ApiUrls.nexus.nexusMeldAssignmentAvailableNexus(meld_id)), {
        params: { postal_code: postalCode },
      }),
    enabled: !!postalCode,
  });
};

const useGetVendorMelds = (vendorId: string, page: number) => {
  return useQuery<{
    results: VendorMeldListViewSerializer;
    count: number;
    next: string | null;
    previous: string | null;
  }>({
    queryKey: meldKeys.vendorMelds(vendorId, page),
    queryFn: () => apiFetch(paginatedVendorMeldUrlBuilder(vendorId, page)),
    keepPreviousData: true,
  });
};

interface OwnerApprovalFormData {
  approval_status: OwnerApproval;
  message: string;
  owner: number;
}

const usePatchOwnerApprovalStatus = ({ meld }: { meld: { id: number } }) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Partial<OwnerApprovalFormData>) =>
      apiPatch(LinkHelper.normalize(ApiUrls.ownerApprovalFinish(meld.id)), data),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldOwnerApprovalUpdatedSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.meldOwnerApprovalUpdatedFailure,
        color: "danger",
      });
    },
  });
};

const usePatchOwnerApprovalAcknowledge = (meld: { id: number }) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      apiPatch(LinkHelper.normalize(ApiUrls.acknowledgeOwnerApproval(meld.id)), { is_acknowledged: true }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldOwnerApprovalAcknowledgedSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.meldOwnerApprovalAcknowledgedFailure,
        color: "danger",
      });
    },
  });
};

const useGetMeldEstimates = (meld: { id: number }) => {
  return useQuery<ManyEstimateSerializer>({
    queryKey: meldKeys.meldEstimates(meld.id),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldEstimates(meld.id))),
    // this query is pre-fetched on the meld details page, staleTime here prevents
    // refetching when the other components using this hook mount
    staleTime: 3000,
  });
};
const useGetMeldEstimatesWithItemTotals = (meld: { id: number }) => {
  return useQuery<ManyEstimateListSerializer>({
    queryKey: meldKeys.meldEstimates(meld.id),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldEstimates(meld.id))),
    // this query is pre-fetched on the meld details page, staleTime here prevents
    // refetching when the other components using this hook mount
    staleTime: 3000,
  });
};

interface RequestMeldEstimateFormData {
  request_notes?: string;
  deadline?: string;
  vendors: number[];
}

const useRequestMeldEstimate = (meld: MeldDetailViewSerializerV2) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData: RequestMeldEstimateFormData) =>
      apiPost(LinkHelper.normalize(ApiUrls.meldEstimates(meld.id)), formData),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldRequestEstimateSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldKeys.meldEstimates(meld.id.toString())).then(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (_) => queryClient.invalidateQueries(meldKeys.detail(meld.id)),
        (error) => error
      );
    },
    onError: () => {
      addToast({
        text: toastMessages.meldRequestEstimateFailure,
        color: "danger",
      });
    },
  });
};

const useBulkRequestOwnerApprovalMeldEstimate = (estimateIds: number[]) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationFn: (showLineItems: boolean) =>
      apiPost(LinkHelper.normalize(ApiUrls.estimateRequestOwnerApproval), {
        approvalEstimates: estimateIds.map((id) => ({ id })),
        showLineItems,
      }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateSuccess,
        color: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.all),
        queryClient.invalidateQueries(estimateKeys.all),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateFailure,
        color: "danger",
      });
    },
  });
};

type AssignMeldMaintenanceData = {
  user_groups: number[];
} & {
  maintenance: AllVendorsListRegisteredOnlyFalse;
};

const usePatchAssignMeldMaintenance = (meld: { id: number | string }, type: "assign" | "unassign") => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  const successToastMessage =
    type === "assign" ? toastMessages.meldAssignedSuccess : toastMessages.meldUnassignedSuccess;
  const failureToastMessage =
    type === "assign" ? toastMessages.meldAssignedFailure : toastMessages.meldUnassignedFailure;

  return useMutation({
    mutationKey: meldKeys.mutations.assign(meld.id.toString()),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mutationFn: ({ extraOnSuccess, ...data }: AssignMeldMaintenanceData & { extraOnSuccess?: () => void }) =>
      apiPatch(LinkHelper.normalize(ApiUrls.assignMaintenance(meld.id)), data),
    onSuccess: (_, args) => {
      addToast({
        text: successToastMessage,
        color: "success",
      });
      args.extraOnSuccess?.();
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.all),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
      ]);
    },
    onError: (err: AxiosError<{ non_field_errors?: string[] }>) => {
      // this shouldn't display a toast
      if (err.response?.data?.non_field_errors?.[0] === "Meld is already assigned to this vendor") {
        return;
      }
      addToast({
        text: failureToastMessage,
        color: "danger",
      });
    },
  });
};

const useGetMeldActivities = (meld: { id: number }) => {
  return useQuery<MeldEvent[]>({
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldActivities(meld.id))),
    queryKey: meldKeys.activities(meld.id),
  });
};

interface ReminderCreatePayload {
  title: string;
  notes: string;
  due: string;
  assignees: number[];
}

const useCreateReminder = (meld: { id: number }) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationKey: meldKeys.mutations.createReminder(meld.id.toString()),
    mutationFn: (data: ReminderCreatePayload) => {
      return apiPost(LinkHelper.normalize(ApiUrls.reminderList), {
        ...data,
        meld: meld.id,
      });
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.reminderCreateSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.reminderCreateFailure,
        color: "danger",
      });
    },
  });
};

interface ReminderUpdatePayload extends ReminderCreatePayload {
  id: number;
}

const useUpdateReminder = (meld: { id: number }) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationKey: meldKeys.mutations.editReminder(meld.id.toString()),
    mutationFn: (data: ReminderUpdatePayload) => {
      return apiPatch(LinkHelper.normalize(ApiUrls.reminderDetail(data.id)), {
        ...data,
        meld: meld.id,
      });
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.reminderUpdateSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.reminderUpdateFailure,
        color: "danger",
      });
    },
  });
};

const useToggleCompleteMeldReminder = (meld: MeldDetailViewSerializerV2) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (reminder: number) => apiPatch(LinkHelper.normalize(ApiUrls.reminderComplete(reminder)), {}),
    onSuccess: () => {
      addToast({
        text: toastMessages.reminderCompleteSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.reminderCompleteFailure,
        color: "danger",
      });
    },
  });
};

const useDeleteMeldReminder = (meld: MeldDetailViewSerializerV2) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationFn: (reminder: number) => apiDelete(LinkHelper.normalize(ApiUrls.reminderDetail(reminder.toString()))),
    onSuccess: () => {
      addToast({
        text: toastMessages.reminderDeleteSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.reminderDeleteFailure,
        color: "danger",
      });
    },
  });
};

const useDeleteMeldWorkLog = (meld: MeldDetailViewSerializerV2) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationFn: (workLogId: number) => apiDelete(LinkHelper.normalize(ApiUrls.workEntryDetail(workLogId))),
    onSuccess: () => {
      addToast({
        text: toastMessages.workLogDeleteSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.workLogDeleteFailure,
        color: "danger",
      });
    },
  });
};

const useBulkPatchUpdateMeldResidents = (meld: MeldDetailViewSerializerV2) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  const url = LinkHelper.normalize(ApiUrls.meldTenants(meld.id.toString()));

  const addResidentsToMeld = useMutation({
    mutationFn: ({ selectedItems }: { selectedItems: Array<{ id: number }> }) => {
      const residents = [...meld.tenants.map((item) => item.id), ...selectedItems.map((item) => item.id)];
      return apiPatch(url, {
        tenants: residents,
      });
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.residentBulkUpdateSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries({ queryKey: meldKeys.detail(meld.id.toString()) });
    },
    onError: () => {
      addToast({
        text: toastMessages.residentBulkUpdateFailure,
        color: "danger",
      });
    },
  });

  const removeResidentsFromMeld = useMutation({
    mutationFn: ({ selectedItems }: { selectedItems: Array<{ id: number }> }) => {
      const residents = meld.tenants
        .map((item) => item.id)
        .filter((id) => !selectedItems.find((item) => id === item.id));
      return apiPatch(url, {
        tenants: residents,
      });
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.residentBulkUpdateSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries({ queryKey: meldKeys.detail(meld.id.toString()) });
    },
    onError: () => {
      addToast({
        text: toastMessages.residentBulkUpdateFailure,
        color: "danger",
      });
    },
  });

  return { addResidentsToMeld, removeResidentsFromMeld };
};

const useSearchMeldTags = (query: string) => {
  return useQuery<WriteableTagSerializer[]>({
    queryFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.meldTagSearchList)}?query=${query}`),
    queryKey: meldKeys.searchTags(query),
  });
};

interface UpdateTagFormData {
  id?: string;
  name: string;
}

const useUpdateMeldTags = (meld: MeldDetailViewSerializerV2) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (tags: UpdateTagFormData[]) =>
      apiPatch(LinkHelper.normalize(ApiUrls.meldTags(meld.id)), {
        tags,
      }),
    onSuccess: () => {
      addToast({
        text: toastMessages.updateMeldTagsSuccess,
        color: "success",
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.updateMeldTagsFailure,
        color: "danger",
      });
    },
  });
};

interface CancelMeldFormData {
  reason?: string;
}

const useCancelMeld = (meld: MeldDetailViewSerializerV2) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData: CancelMeldFormData) =>
      apiPatch(LinkHelper.normalize(ApiUrls.meldCancel(meld.id)), {
        manager_cancellation_reason: formData.reason || "",
      }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldCancelSuccess,
        color: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.detail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldCancelFailure,
        color: "danger",
      });
    },
  });
};

interface FinishMeldFormData {
  date: string;
  is_complete: boolean;
  reason?: string;
}

const useFinishMeld = (meld: MeldDetailViewSerializerV2) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData: FinishMeldFormData) =>
      apiPatch(LinkHelper.normalize(ApiUrls.completeMeld(meld.id)), formData),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldFinishSuccess,
        color: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.detail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldFinishFailure,
        color: "danger",
      });
    },
  });
};

const useGetManagementAgentWatching = (meld: { id: number | string }) => {
  const watching = useQuery<{
    watching: boolean;
  }>({
    queryKey: meldKeys.managerWatch(meld.id),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldWatch(meld.id))),
    enabled: !!meld.id,
    // this query is pre-fetched on the meld details page, staleTime here prevents
    // refetching when the other components using this hook mount
    staleTime: 3000,
  });
  return watching;
};

const useManagementAgentWatch = (meld: { id: number }) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return apiPost(LinkHelper.normalize(ApiUrls.meldWatch(meld.id)), {});
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.managementAgentWatch,
        color: "success",
      });
      return Promise.allSettled([
        queryClient.invalidateQueries(meldKeys.managerWatch(meld.id.toString())),
        queryClient.invalidateQueries(meldKeys.managerWatchlist(meld.id.toString())),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.managementAgentWatchError,
        color: "danger",
      });
    },
  });
};

const useManagementAgentUnwatch = (meld: { id: number }) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return apiDelete(LinkHelper.normalize(ApiUrls.meldWatch(meld.id)));
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.managementAgentUnwatch,
        color: "success",
      });
      return Promise.allSettled([
        queryClient.invalidateQueries(meldKeys.managerWatch(meld.id.toString())),
        queryClient.invalidateQueries(meldKeys.managerWatchlist(meld.id.toString())),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.managementAgentUnwatchError,
        color: "danger",
      });
    },
  });
};

const useGetManagementAgentWatchlist = (meld: { id: number | string }) => {
  return useQuery<{
    results: MeldManagerWatchlistViewSerializer[];
    count: number;
    next: string | null;
    previous: string | null;
  }>({
    queryKey: meldKeys.managerWatchlist(meld.id),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldWatchlist(meld.id))),
    enabled: !!meld.id,
    // this query is pre-fetched on the meld details page, staleTime here prevents
    // refetching when the other components using this hook mount
    staleTime: 3000,
  });
};

const useGetAvailableAgentsWatchlist = (
  meld: { id: number },
  queryParams: { exclude_ids: string; property_group_ids: string }
) => {
  return useQuery<ManagementAgent[]>({
    queryKey: meldKeys.managerAvailableWatchlist(meld.id, queryParams),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldWatchSearchAll), { params: queryParams }),
  });
};

const useAddWatchersToWatchlist = (meld: { id: number }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (management_agent_ids: number[]) => {
      return Promise.all(
        management_agent_ids.map((management_agent_id) => {
          return apiPost(LinkHelper.normalize(ApiUrls.meldWatchlist(meld.id)), { management_agent_id });
        })
      );
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(meldKeys.managerWatchlist(meld.id.toString()));
    },
    onError: () => {},
  });
};

const useDeleteWatchersFromWatchlist = (meld: { id: number }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (management_agent_ids: number[]) => {
      return Promise.all(
        management_agent_ids.map((management_agent_id) => {
          return apiDelete(LinkHelper.normalize(ApiUrls.meldWatchlistDelete(meld.id, management_agent_id)));
        })
      );
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(meldKeys.managerWatchlist(meld.id.toString()));
    },
    onError: () => {},
  });
};

const useCalculateProximityRequest = (meldId: number) => {
  return useMutation(
    (proximityData: { distance: number; meld_id: number }) =>
      apiPost(LinkHelper.normalize(ApiUrls.meldProximity(meldId)), proximityData) as Promise<
        MeldDistanceOutputSerializer[]
      >,
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );
};

interface MergeMeldParams {
  destinationId: number;
  sourceIds: number[];
}
const useMergeMelds = () => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation(
    (params: MergeMeldParams) => {
      const { destinationId, sourceIds } = params;
      return apiPost(LinkHelper.normalize(ApiUrls.meldMerge(destinationId)), {
        destination_id: destinationId,
        source_ids: sourceIds,
      });
    },
    {
      onSuccess: () => {
        addToast({
          text: toastMessages.meldsMergeSuccess,
          color: "success",
        });
        return Promise.all([
          queryClient.invalidateQueries(meldKeys.all),
          queryClient.resetQueries(calendarMeldKeys.all()),
        ]);
      },
      onError: () => {
        addToast({
          text: toastMessages.meldsMergeFailure,
          color: "danger",
        });
      },
    }
  );
};

const useGetMergedMeldDetails = (mergedMeldId: number) => {
  return useQuery<MergedMeld>({
    queryKey: meldKeys.mergedMeld(mergedMeldId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.mergedMeldDetail(mergedMeldId))),
  });
};

interface CancelAppointmentFormData {
  reason?: string;
  appointmentId: number;
}

const useCancelAppointment = (meld: { id: number }, additionalOnSuccess?: VoidFunction) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData: CancelAppointmentFormData) =>
      apiPatch(LinkHelper.normalize(ApiUrls.managementAppointmentCancel(formData.appointmentId)), {
        manager_cancellation_reason: formData.reason || "",
      }),
    onSuccess: () => {
      addToast({
        text: toastMessages.appointmentCancelSuccess,
        color: "success",
      });
      additionalOnSuccess?.();
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.detail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.managerScheduledEventsAll()),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.appointmentCancelFailure,
        color: "danger",
      });
    },
  });
};

const useAgentCancelVendorAppointment = (meld: { id: number }, additionalOnSuccess?: VoidFunction) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData: CancelAppointmentFormData) =>
      apiPatch(LinkHelper.normalize(ApiUrls.managementCancelVendorAppointment(formData.appointmentId)), {
        manager_cancellation_reason: formData.reason || "",
      }),
    onSuccess: () => {
      addToast({
        text: toastMessages.appointmentCancelSuccess,
        color: "success",
      });
      additionalOnSuccess?.();
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.detail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.vendorScheduledEventsAll()),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.appointmentCancelFailure,
        color: "danger",
      });
    },
  });
};

export {
  useGetPropertyLevelMelds,
  useGetPropertyUnitLevelMelds,
  useGetUnitMelds,
  useGetVendorMelds,
  useGetMeldNexusesByRange,
  useGetResidentMelds,
  useGetMeldDetailsQuery,
  usePatchMeldDetails,
  usePatchMeldDetailsNotes,
  useGetRecurringMeldMelds,
  usePatchOwnerApprovalStatus,
  usePatchOwnerApprovalAcknowledge,
  OwnerApprovalFormData,
  useGetMeldEstimates,
  useGetMeldEstimatesWithItemTotals,
  RequestMeldEstimateFormData,
  useRequestMeldEstimate,
  useBulkRequestOwnerApprovalMeldEstimate,
  AssignMeldMaintenanceData,
  usePatchAssignMeldMaintenance,
  useGetMeldActivities,
  ReminderCreatePayload,
  useCreateReminder,
  ReminderUpdatePayload,
  useUpdateReminder,
  useToggleCompleteMeldReminder,
  useDeleteMeldReminder,
  useGetWorkEntriesMelds,
  useGetOpenWorkEntries,
  useGetWorkEntryDetail,
  WorkEntryCreatePayload,
  useCreateWorkEntry,
  WorkEntryUpdatePayload,
  usePatchWorkEntry,
  useDeleteMeldWorkLog,
  useGetMeldResidentDetails,
  usePatchCoordinator,
  EditCoordinatorUpdatePayload,
  useSearchMeldTags,
  UpdateTagFormData,
  useUpdateMeldTags,
  useBulkPatchUpdateMeldResidents,
  CancelMeldFormData,
  useCancelMeld,
  FinishMeldFormData,
  useFinishMeld,
  useGetManagementAgentWatching,
  useManagementAgentWatch,
  useManagementAgentUnwatch,
  useGetManagementAgentWatchlist,
  useGetAvailableAgentsWatchlist,
  useAddWatchersToWatchlist,
  useDeleteWatchersFromWatchlist,
  useCalculateProximityRequest,
  useMergeMelds,
  useGetMergedMeldDetails,
  CancelAppointmentFormData,
  useCancelAppointment,
  useAgentCancelVendorAppointment,
  meldKeys,
};
