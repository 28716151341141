import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiSpacer } from "@elastic/eui";

const PmPageLoading = ({
  size = "xxl",
  disableTopSpacing = false,
}: {
  size?: "s" | "m" | "l" | "xl" | "xxl";
  disableTopSpacing?: boolean;
}) => (
  <>
    {!disableTopSpacing && <EuiSpacer size="l" />}
    <EuiFlexGroup alignItems="center" justifyContent="spaceAround" direction="column" gutterSize="s">
      <EuiFlexItem grow={false} data-testid="page-loading-indicator">
        <EuiLoadingSpinner size={size} />
      </EuiFlexItem>
    </EuiFlexGroup>
  </>
);

export { PmPageLoading };
