var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { create } from "zustand";
import { EuiTextColor } from "@elastic/eui";
import { colors } from "@pm-frontend/styles";
let toastIdCounter = 0;
const wrapToastText = (toast) => {
  if (toast.color === "success") {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(EuiTextColor, { style: { color: colors.interface.green.default } }, " ", toast.text, " "));
  }
  if (toast.color === "danger") {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(EuiTextColor, { style: { color: colors.interface.red.default } }, " ", toast.text, " "));
  }
  return toast.text;
};
const useToastStore = create((set) => ({
  toasts: [],
  addToast: (toast) => {
    set(() => {
      const newToastText = wrapToastText(toast);
      toastIdCounter += 1;
      return {
        // we are experimenting with one toast for now, leaving this in for future reference
        // toasts: state.toasts.concat({ ...toast, id: `toast${toastIdCounter}` }),
        toasts: [__spreadProps(__spreadValues({}, toast), { text: newToastText, id: `toast${toastIdCounter}` })]
      };
    });
  },
  dismissToast: (removedToast) => set((state) => ({ toasts: state.toasts.filter((toast) => toast.id !== removedToast.id) }))
}));
const toastMessages = {
  appSettingsMarkupDefaults: "Failed to fetch line item markup defaults",
  alternativeEventCreateSuccess: "Event scheduled successfully",
  alternativeEventCreateFailure: "Event not scheduled",
  alternativeEventSaveSuccess: "Event saved successfully",
  alternativeEventSaveFailure: "Event not saved",
  alternativeEventDeleteSuccess: "Event deleted successfully",
  alternativeEventDeleteFailure: "Event not deleted",
  appointmentCancelSuccess: "Appointment canceled successfully",
  appointmentCancelFailure: "Appointment not canceled",
  calendarAddManagerAvailabilitiesSuccess: "Availabilities added successfully",
  calendarAddManagerAvailabilitiesFailure: "Availabilities not added",
  calendarBatchScheduleSuccess: "Events scheduled",
  calendarBatchScheduleFailure: "Events not scheduled",
  chatDeleteSuccess: "Chat deleted successfully",
  chatDeleteFailure: "Chat not deleted",
  chatEditSuccess: "Chat updated successfully",
  chatEditFailure: "Chat not updated",
  chatTemplateFetchFailure: "Something went wrong fetching the chat template",
  coordinatorUpdateSuccess: "Coordinator updated successfully",
  coordinatorUpdateFailure: "Coordinator not updated",
  defaultGlSavedSuccess: "Default GL account saved successfully",
  defaultGlSavedFailure: "Default GL account not saved",
  enterpriseAgentAddedSuccess: "User added successfully",
  enterpriseAgentRemovedSuccess: "User removed successfully",
  enterpriseAgentAddedFailure: "User not added",
  enterpriseAgentRemovedFailure: "User not removed",
  errorTitle: "Something went wrong",
  estimateUpdateSuccess: "Estimate update successful",
  estimateUpdateFailure: "Estimate update failed",
  estimateCreationSuccess: "Estimate creation successful",
  estimateCreationFailure: "Estimate creation failed",
  estimateLineItemCreationSuccess: "Estimate line item creation successful",
  estimateLineItemCreationFailure: "Estimate line item creation failed",
  estimateLineItemUpdateSuccess: "Estimate line item update successful",
  estimateLineItemUpdateFailure: "Estimate line item update failed",
  estimateLineItemsFetchFailure: "Failed to fetch estimate line items",
  expenditureDeletedSuccess: "Expenditure deleted successfully",
  expenditureDeletedFailure: "Expenditure not deleted",
  expenditureSetApprovedSuccess: "Set to Approved",
  expenditureSetApprovedError: "Not set to Approved",
  expenditureSetBilledSuccess: "Set to Billed",
  expenditureSetBilledError: "Not set to Billed",
  expenditureSetDraftSuccess: "Set to Draft",
  expenditureSetDraftError: "Not set to Draft",
  expenditureSetHoldSuccess: "Set to On Hold",
  expenditureSetHoldError: "Not set to On Hold",
  expenditureSetReviewSuccess: "Set to In Review",
  expenditureSetReviewError: "Not set to In Review",
  filesUploadSuccess: "Files uploaded successfully",
  filesUploadFailure: "Files not uploaded",
  filesDeleteSuccess: "Files deleted successfully",
  filesDeleteFailure: "Files not deleted",
  filesDeleteProhibitedResident: "Resident-uploaded files cannot be deleted",
  filesDeleteProhibitedVendor: "Vendor-uploaded files cannot be deleted",
  fileDownloadSuccess: "Files downloaded successfully",
  fileDownloadFailure: "File not downloaded",
  fileExpirationUpdateSuccess: "Expiration updated successfully",
  fileExpirationUpdateFailure: "Expiration not updated",
  glRuleCreatedSuccess: "GL Rule created successfully",
  glRuleCreatedFailure: "GL Rule not created",
  glRuleAddedSuccess: "GL Rule added successfully",
  glRuleAddedFailure: "GL Rule not added",
  glRuleEditedSuccess: "GL Rule edited successfully",
  glRuleEditedFailure: "GL Rule not edited",
  glRulesUpdateSuccess: "GL Rules updated successfully",
  glRulesUpdateFailure: "GL Rules not updated",
  invoiceSetDeclineSuccess: "Invoice declined successfully",
  invoiceSetDeclineFailure: "Invoice not declined",
  invoiceSetHoldSuccess: "Request sent successfully",
  invoiceSetHoldFailure: "Request not sent",
  invoiceSetReviewSuccess: "Set to In Review",
  invoiceSetReviewError: "Not set to In Review",
  invoiceSetApprovedSuccess: "Set to Approved",
  invoiceSetApprovedError: "Not set to Approved",
  managementAgentWatch: "You will receive notifications for this Meld",
  managementAgentUnwatch: "You will no longer receive notifications for this Meld",
  managementAgentWatchError: "Could not watch Meld",
  managementAgentUnwatchError: "Could not unwatch Meld",
  meldCreateSuccess: "Meld created",
  meldCreateError: "Meld not created",
  meldFilesSharedSuccess: "Files shared successfully",
  meldFilesSharedFailure: "Files not shared",
  meldAssignedSuccess: "Meld assigned successfully",
  meldAssignedFailure: "Meld not assigned",
  meldAssignedScheduleMeldSuccess: "Meld assigned and scheduled",
  meldAssignedScheduleMeldFailure: "Meld not assigned and scheduled",
  meldUnassignedSuccess: "Meld unassigned successfully",
  meldUnassignedFailure: "Meld not unassigned",
  meldUpdateSuccess: "Meld updated successfully",
  meldUpdateFailure: "Meld not updated",
  meldCancelSuccess: "Meld canceled successfully",
  meldCancelFailure: "Meld not canceled",
  meldFinishSuccess: "Meld finished successfully",
  meldFinishFailure: "Meld not finished",
  meldCreateEstimateSuccess: "Estimate created successfully",
  meldCreateEstimateFailure: "Estimate not created",
  meldRequestEstimateSuccess: "Estimates requested successfully",
  meldRequestEstimateFailure: "Estimates not requested",
  meldApproveEstimateSuccess: "Estimate approved successfully",
  meldApproveEstimateFailure: "Estimate not approved",
  meldCloseEstimateSuccess: "Estimate closed successfully",
  meldCloseEstimateFailure: "Estimate not closed",
  meldRequestOwnerApprovalEstimateSuccess: "Approval requested successfully",
  meldRequestOwnerApprovalEstimateFailure: "Approval not requested",
  meldCancelRequestOwnerApprovalEstimateSuccess: "Approval request cancelled successfully",
  meldCancelRequestOwnerApprovalEstimateFailure: "Approval request not cancelled",
  meldMentionSucces: "Users mentioned successfully",
  meldsMergeSuccess: "Melds merged successfully",
  meldsMergeFailure: "Melds not merged",
  meldListExportCSVSuccess: "The CSV file is on its way to your email",
  meldListExportCSVFailure: "An error occurred exporting the CSV",
  meldListSaveNewFilterFailure: "Filter not created",
  meldListSaveNewFilterSuccess: "Filter created successfully",
  meldListSavedFilterDeleteError: "Saved filter not deleted",
  meldListSavedFilterDeleteSuccess: "Saved filter deleted successfully",
  meldListSavedFilterEditError: "Saved filter not updated",
  meldListSavedFilterEditSuccess: "Saved filter updated successfully",
  meldListSavedFilterReorderError: "Saved filter not reordered",
  meldListSavedFilterReorderSuccess: "Saved filter reordered successfully",
  meldListUpdateFilterFailure: "Filter not updated",
  meldListUpdateFilterSuccess: "Filter updated successfully",
  modalSuccess: "Saved successfully",
  modalFailure: "Something went wrong",
  nexusConnectSuccess: "Connection requested successfully",
  nexusConnectFailure: "Connection requested failed",
  nexusManagerCommentFailure: "Chat failed to send",
  nexusNexusCommentFailure: "Chat failed to send",
  nexusRequestUpdateSuccess: "Nexus request updated successfully",
  nexusRequestUpdateFailure: "Nexus request not updated",
  nexusAPIKeyCreateSuccess: "API key created successfully",
  nexusAPIKeyCreateFailure: "API key create failed",
  nexusAPIKeyRevokeSuccess: "API key revoked successfully",
  nexusAPIKeyRevokeFailure: "API key revoke failed",
  nexusAPIKeyCopySuccess: "Copied to clipboard successfully",
  nexusSettingsUpdateSuccess: "Settings updated successfully",
  nexusSettingsUpdateFailure: "Settings update failed",
  nexusFileCreateSuccess: "File created successfully",
  nexusFileCreateFailure: "File create failed",
  nexusFileRemoveSuccess: "File removed successfully",
  nexusFileRemoveFailure: "File remove failed",
  nexusAgentRemoveSuccess: "User removed successfully",
  nexusAgentRemoveFailure: "User remove failed",
  nexusAgentCreateSuccess: "User created successfully",
  nexusAgentCreateFailure: "User create failed",
  ownerBulkUpdateSuccess: "Owners updated successfully",
  ownerBulkUpdateFailure: "Owners not updated",
  ownerCreationSuccess: "Owner created successfully",
  ownerCreationFailure: "Owner not created",
  ownerDeleteSuccess: "Owner deleted successfully",
  ownerDeleteFailure: "Owner not deleted",
  ownerInviteSuccess: "Owner invited successfully",
  ownerInviteFailure: "Owner not invited",
  ownerInviteCancelSuccess: "Invite canceled successfully",
  ownerInviteCancelFailure: "Invite not canceled",
  ownerInviteResendSuccess: "Invite re-sent successfully",
  ownerInviteResendFailure: "Invite not re-sent",
  ownerUpdateSuccess: "Owner updated successfully",
  ownerUpdateFailure: "Owner not updated",
  propertyBulkUpdateSuccess: "Properties updated successfully",
  propertyBulkUpdateFailure: "Properties not updated",
  ownerServicesDeleteSuccess: "Services deleted successfully",
  ownerServicesDeleteFailure: "Services not deleted",
  ownerServicesPublishSuccess: "Service updated successfully",
  ownerServicesPublishFailure: "Service not updated",
  ownerServicesOwnerOptOutSuccess: "Owner removed successfully",
  ownerServicesOwnerOptOutFailure: "Owner not removed",
  ownerServicesUpdatedSuccess: "Service updated successfully",
  ownerServicesUpdatedFailure: "Service not updated",
  propertyDeleteSuccess: "Property deactivated successfully",
  propertyDeleteFailure: "Property not deactivated",
  propertyEditSuccess: "Property updated successfully",
  propertyEditMaintenanceLimitSuccess: "Maintenance limit updated successfully",
  propertyReactivateSuccess: "Property reactivated successfully",
  propertyReactivateFailure: "Property not reactivated",
  propertyGroupCreateSuccess: "Property group created successfully",
  propertyGroupCreateFailure: "Property group not created",
  propertyGroupDeleteSuccess: "Property group deleted successfully",
  propertyGroupDeleteFailure: "Property group not deleted",
  propertyGroupEditSuccess: "Property group updated successfully",
  propertyGroupEditFailure: "Property Group not updated",
  meldOwnerApprovalAcknowledgedSuccess: "Approval acknowledged successfully",
  meldOwnerApprovalAcknowledgedFailure: "Approval not acknowledged",
  meldOwnerApprovalUpdatedSuccess: "Approval updated successfully",
  meldOwnerApprovalUpdatedFailure: "Approval not updated",
  quickbooksInvoiceSuccess: "Invoice submitted from Quickbooks successfully",
  quickbooksInvoiceFailure: "Invoice from QuickBooks not submitted",
  recurringMeldCreatedSuccess: "Recurring Meld created",
  recurringMeldCreatedFailure: "Recurring Meld not created",
  recurringMeldDeleteSuccess: "Recurring Meld deleted successfully",
  recurringMeldDeleteFailure: "Recurring Meld not deleted",
  reportCreateSuccess: "Report Emailed Successfully",
  reportCreateFailure: "Report Not Emailed",
  rescheduleMeldSuccess: "Appointment rescheduled successfully",
  rescheduleMeldFailure: "Appointment not rescheduled",
  residentBulkUpdateSuccess: "Residents updated successfully",
  residentBulkUpdateFailure: "Residents not updated",
  residentDeleteSuccess: "Resident deleted successfully",
  residentDeleteFailure: "Resident not deleted",
  residentInviteSuccess: "Resident invited successfully",
  residentInviteFailure: "Resident not invited",
  residentInvitationCancelSuccess: "Invite canceled successfully",
  residentInvitationCancelFailure: "Invite not canceled",
  residentPasswordResetSuccess: "Instructions sent successfully",
  residentPasswordResetFailure: "Instructions not sent",
  residentUpdateSuccess: "Resident updated successfully",
  residentUpdateFailure: "Resident not updated",
  residentUpdateNotesSuccess: "Notes updated successfully",
  residentUpdateNotesFailure: "Notes not updated",
  scheduleMeldSuccess: "Meld scheduled",
  scheduleMeldFailure: "Meld not scheduled",
  unitDeactivateSuccess: "Unit deactivated successfully",
  unitDeactivateFailure: "Unit not deactivated",
  unitReactivateSuccess: "Unit reactivated successfully",
  unitReactivateFailure: "Unit not reactivated",
  unitNotesUpdatedSuccess: "Notes updated successfully",
  unitNotesUpdatedFailure: "Notes not updated",
  unitUpdatedSuccess: "Unit updated successfully",
  unitUpdatedFailure: "Unit not updated",
  unitUpdateResidentsSuccess: "Residents updated successfully",
  unitUpdateResidentsFailure: "Residents not updated",
  vendorDeleteSuccess: "Vendor deleted successfully",
  vendorDeleteFailure: "Vendor not deleted",
  vendorAssignmentsAllowSuccess: "Vendor can now be assigned melds",
  vendorAssignmentsAllowFailure: "Failed to allow vendor to be assigned melds",
  vendorAssignmentsProhibitSuccess: "Vendor can no longer be assigned melds",
  vendorAssignmentsProhibitFailure: "Failed to prohibit vendor from being assigned melds",
  vendorLinkSuccess: "Vendor linked successfully",
  vendorLinkFailure: "Vendor not linked",
  vendorNotesUpdateSuccess: "Notes updated successfully",
  vendorNotesUpdateFailure: "Notes not updated",
  vendorPropertyGroupsUpdateSuccess: "Property groups updated successfully",
  vendorPropertyGroupsUpdateFailure: "Property groups not updated",
  vendorRequestChangesSuccess: "Request sent successfully",
  vendorRequestChangesFailure: "Request not sent",
  vendorUnlinkSuccess: "Vendor unlinked successfully",
  vendorUnlinkError: "Vendor not unlinked",
  reminderCreateSuccess: "Reminder created successfully",
  reminderCreateFailure: "Reminder not created",
  reminderUpdateSuccess: "Reminder updated successfully",
  reminderUpdateFailure: "Reminder not updated",
  reminderCompleteSuccess: "Reminder updated successfully",
  reminderCompleteFailure: "Reminder not updated",
  reminderDeleteSuccess: "Reminder deleted successfully",
  reminderDeleteFailure: "Reminder not deleted",
  workLogCreateSuccess: "Work log created successfully",
  workLogCreateFailure: "Work log not created",
  workLogUpdateSuccess: "Work log updated successfully",
  workLogUpdateFailure: "Work log not updated",
  workLogDeleteSuccess: "Work log deleted successfully",
  workLogDeleteFailure: "Work log not deleted",
  updateMeldTagsSuccess: "Tags updated successfully",
  updateMeldTagsFailure: "Tags not updated"
};
const useAddToast = () => useToastStore((state) => state.addToast);
const useToasts = () => useToastStore((state) => state.toasts);
const useDismissToast = () => useToastStore((state) => state.dismissToast);
export { useAddToast, useToasts, useDismissToast, toastMessages };
