var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { apiDelete, apiFetch, apiPatch, apiPost } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { toastMessages, useAddToast } from "@pm-frontend/shared/store/toast";
import { estimateKeys } from "@pm-frontend/routes/Estimates/queries";
import { calendarMeldKeys } from "../Calendar/queries";
const meldKeys = {
  all: ["melds"],
  detail: (id) => [...meldKeys.all, id.toString()],
  list: (params) => [...meldKeys.all, "list", params],
  unitMelds: (id) => [...meldKeys.all, "unit", id.toString()],
  residentMelds: (id) => [...meldKeys.all, "resident", id],
  vendorMelds: (id, page) => [...meldKeys.all, "vendor", id, "page", page],
  meldAvailableNexus: (meldId, postalCode) => [
    ...meldKeys.detail(meldId),
    "nexus-range",
    meldId.toString(),
    postalCode == null ? void 0 : postalCode.toString()
  ],
  propertyMelds: (id) => [...meldKeys.all, "property", id],
  propertyPropertyMelds: (id) => [...meldKeys.propertyMelds(id), "propertyLevel"],
  propertyUnitMelds: (id) => [...meldKeys.propertyMelds(id), "unitLevel"],
  recurringMelds: (id) => [...meldKeys.all, "recurring-meld", id.toString()],
  meldEstimates: (id) => [...meldKeys.detail(id), "estimates"],
  mergedMeld: (id) => [...meldKeys.detail(id), "merged-meld"],
  activities: (id) => [...meldKeys.detail(id), "activities"],
  workLogs: (id) => [...meldKeys.detail(id), "work-logs"],
  meldWorkLog: (meldId, workEntryId) => [
    ...meldKeys.detail(meldId),
    "work-logs",
    workEntryId.toString()
  ],
  currentAgentOpenWorkLog: () => [...meldKeys.all, "current-agent-open-work-logs"],
  searchTags: (query) => [...meldKeys.all, "search-tags", query],
  residents: (id) => [...meldKeys.detail(id), "residents"],
  recommend: (id) => [...meldKeys.detail(id), "recommend"],
  managerWatch: (id) => [...meldKeys.managerWatchlist(id), "manager-watch"],
  managerWatchlist: (id) => [...meldKeys.detail(id), "manager-watchlist"],
  managerAvailableWatchlist: (id, queryParams) => [...meldKeys.managerWatchlist(id), queryParams, "manager-available-watchlist"],
  mutations: {
    assign: (id) => [...meldKeys.detail(id), "assign"],
    patch: (id) => [...meldKeys.detail(id), "patch"],
    createReminder: (id) => [...meldKeys.detail(id), "create-reminder"],
    editReminder: (id) => [...meldKeys.detail(id), "edit-reminder"],
    createMeld: () => [...meldKeys.all, "create-edit-melds"],
    createRecurringMeld: () => [...meldKeys.all, "create-edit-recurring-melds"]
  }
};
const useGetPropertyLevelMelds = (propertyId) => {
  return useQuery({
    queryKey: meldKeys.propertyPropertyMelds(propertyId),
    queryFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.abbreviatedMeldList)}?prop=${propertyId}&ordering=-created`)
  });
};
const useGetPropertyUnitLevelMelds = (propertyId) => {
  return useQuery({
    queryKey: meldKeys.propertyUnitMelds(propertyId),
    queryFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.abbreviatedMeldList)}?unit_level_melds=${propertyId}&ordering=-created`)
  });
};
const useGetUnitMelds = (unitId) => {
  return useQuery({
    queryKey: meldKeys.unitMelds(unitId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.unitMelds(unitId)))
  });
};
const useGetResidentMelds = (residentId) => {
  return useQuery({
    queryKey: meldKeys.residentMelds(residentId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.tenantMeldList(residentId)))
  });
};
const useGetMeldDetailsQuery = (meldId) => {
  return useQuery({
    queryKey: meldKeys.detail(meldId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldDetail(meldId))),
    enabled: !!meldId
  });
};
const useGetMeldResidentDetails = (meldId, residentIds) => {
  return useQuery({
    queryKey: meldKeys.residents(meldId),
    queryFn: () => Promise.all(residentIds.map((residentId) => apiFetch(LinkHelper.normalize(ApiUrls.tenantDetail(residentId)))))
  });
};
const useGetWorkEntriesMelds = (meldId) => {
  return useQuery({
    queryKey: meldKeys.workLogs(meldId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.workEntryList(meldId))),
    enabled: !!meldId,
    // this query is pre-fetched on the meld details page, staleTime here prevents
    // refetching when the other components using this hook mount
    staleTime: 3e3
  });
};
const FIVE_MINUTES_IN_MS = 5 * 60 * 1e3;
const useGetOpenWorkEntries = () => {
  return useQuery({
    queryKey: meldKeys.currentAgentOpenWorkLog(),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.workEntryOpenList())),
    // to prevent refetching on page mounts. This query is most often
    // manually invalidated to trigger refreshes
    staleTime: FIVE_MINUTES_IN_MS,
    cacheTime: FIVE_MINUTES_IN_MS
  });
};
const useGetWorkEntryDetail = (meldId, workEntryId) => {
  return useQuery({
    queryKey: meldKeys.meldWorkLog(meldId, workEntryId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.workEntryDetail(workEntryId)))
  });
};
const usePatchCoordinator = (meld) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: (data) => {
      return apiPatch(LinkHelper.normalize(ApiUrls.meldAssignedCoordinator(meld.id)), { coordinator: data });
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.coordinatorUpdateSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id));
    },
    onError: () => {
      addToast({
        text: toastMessages.coordinatorUpdateFailure,
        color: "danger"
      });
    }
  });
};
const useCreateWorkEntry = (meld) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: (data) => {
      return apiPost(LinkHelper.normalize(ApiUrls.workEntryList(meld.id)), __spreadProps(__spreadValues({}, data), {
        meld: meld.id
      }));
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.workLogCreateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.workLogs(meld.id)),
        queryClient.invalidateQueries(meldKeys.currentAgentOpenWorkLog())
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.workLogCreateFailure,
        color: "danger"
      });
    }
  });
};
const usePatchWorkEntry = (meld) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: (data) => {
      return apiPatch(LinkHelper.normalize(ApiUrls.workEntryDetail(data.id)), data);
    },
    onSuccess: (_, requestPayload) => {
      addToast({
        text: toastMessages.workLogUpdateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.workLogs(meld.id.toString())),
        queryClient.removeQueries(meldKeys.meldWorkLog(meld.id.toString(), requestPayload.id.toString())),
        queryClient.invalidateQueries(meldKeys.currentAgentOpenWorkLog())
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.workLogUpdateFailure,
        color: "danger"
      });
    }
  });
};
const usePatchMeldDetails = (meld) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationKey: meldKeys.mutations.patch(meld.id.toString()),
    mutationFn: (data) => {
      const newData = __spreadValues(__spreadValues({}, meld), data);
      return apiPatch(LinkHelper.normalize(ApiUrls.meldDetail(meld.id)), newData);
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.meldUpdateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.detail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldUpdateFailure,
        color: "danger"
      });
    }
  });
};
const usePatchMeldDetailsNotes = (meld) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationKey: meldKeys.mutations.patch(meld.id.toString()),
    mutationFn: (data) => {
      const newData = __spreadValues({}, data);
      return apiPatch(LinkHelper.normalize(ApiUrls.meldDetailNotes(meld.id)), newData);
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.meldUpdateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.detail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldUpdateFailure,
        color: "danger"
      });
    }
  });
};
const useGetRecurringMeldMelds = (recurringId) => {
  return useQuery({
    queryKey: meldKeys.recurringMelds(recurringId),
    queryFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.abbreviatedMeldList)}?recurring_meld_related_melds=${recurringId}`)
  });
};
const paginatedVendorMeldUrlBuilder = (vendorId, currentPage) => {
  const PAGE_SIZE = 10;
  const offset = currentPage * PAGE_SIZE;
  let url = LinkHelper.normalize(ApiUrls.vendorMeldList(vendorId));
  if (currentPage > 0) {
    url = `${url}?limit=${PAGE_SIZE}&offset=${offset}`;
  } else {
    url = `${url}?limit=${PAGE_SIZE}`;
  }
  return url;
};
const useGetMeldNexusesByRange = (meld_id, postalCode) => {
  return useQuery({
    queryKey: meldKeys.meldAvailableNexus(meld_id, postalCode),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.nexus.nexusMeldAssignmentAvailableNexus(meld_id)), {
      params: { postal_code: postalCode }
    }),
    enabled: !!postalCode
  });
};
const useGetVendorMelds = (vendorId, page) => {
  return useQuery({
    queryKey: meldKeys.vendorMelds(vendorId, page),
    queryFn: () => apiFetch(paginatedVendorMeldUrlBuilder(vendorId, page)),
    keepPreviousData: true
  });
};
const usePatchOwnerApprovalStatus = ({ meld }) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => apiPatch(LinkHelper.normalize(ApiUrls.ownerApprovalFinish(meld.id)), data),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldOwnerApprovalUpdatedSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.meldOwnerApprovalUpdatedFailure,
        color: "danger"
      });
    }
  });
};
const usePatchOwnerApprovalAcknowledge = (meld) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => apiPatch(LinkHelper.normalize(ApiUrls.acknowledgeOwnerApproval(meld.id)), { is_acknowledged: true }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldOwnerApprovalAcknowledgedSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.meldOwnerApprovalAcknowledgedFailure,
        color: "danger"
      });
    }
  });
};
const useGetMeldEstimates = (meld) => {
  return useQuery({
    queryKey: meldKeys.meldEstimates(meld.id),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldEstimates(meld.id))),
    // this query is pre-fetched on the meld details page, staleTime here prevents
    // refetching when the other components using this hook mount
    staleTime: 3e3
  });
};
const useGetMeldEstimatesWithItemTotals = (meld) => {
  return useQuery({
    queryKey: meldKeys.meldEstimates(meld.id),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldEstimates(meld.id))),
    // this query is pre-fetched on the meld details page, staleTime here prevents
    // refetching when the other components using this hook mount
    staleTime: 3e3
  });
};
const useRequestMeldEstimate = (meld) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (formData) => apiPost(LinkHelper.normalize(ApiUrls.meldEstimates(meld.id)), formData),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldRequestEstimateSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldKeys.meldEstimates(meld.id.toString())).then(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (_) => queryClient.invalidateQueries(meldKeys.detail(meld.id)),
        (error) => error
      );
    },
    onError: () => {
      addToast({
        text: toastMessages.meldRequestEstimateFailure,
        color: "danger"
      });
    }
  });
};
const useBulkRequestOwnerApprovalMeldEstimate = (estimateIds) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: (showLineItems) => apiPost(LinkHelper.normalize(ApiUrls.estimateRequestOwnerApproval), {
      approvalEstimates: estimateIds.map((id) => ({ id })),
      showLineItems
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.all),
        queryClient.invalidateQueries(estimateKeys.all)
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateFailure,
        color: "danger"
      });
    }
  });
};
const usePatchAssignMeldMaintenance = (meld, type) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  const successToastMessage = type === "assign" ? toastMessages.meldAssignedSuccess : toastMessages.meldUnassignedSuccess;
  const failureToastMessage = type === "assign" ? toastMessages.meldAssignedFailure : toastMessages.meldUnassignedFailure;
  return useMutation({
    mutationKey: meldKeys.mutations.assign(meld.id.toString()),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mutationFn: (_a) => {
      var _b = _a, { extraOnSuccess } = _b, data = __objRest(_b, ["extraOnSuccess"]);
      return apiPatch(LinkHelper.normalize(ApiUrls.assignMaintenance(meld.id)), data);
    },
    onSuccess: (_, args) => {
      var _a;
      addToast({
        text: successToastMessage,
        color: "success"
      });
      (_a = args.extraOnSuccess) == null ? void 0 : _a.call(args);
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.all),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString()))
      ]);
    },
    onError: (err) => {
      var _a, _b, _c;
      if (((_c = (_b = (_a = err.response) == null ? void 0 : _a.data) == null ? void 0 : _b.non_field_errors) == null ? void 0 : _c[0]) === "Meld is already assigned to this vendor") {
        return;
      }
      addToast({
        text: failureToastMessage,
        color: "danger"
      });
    }
  });
};
const useGetMeldActivities = (meld) => {
  return useQuery({
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldActivities(meld.id))),
    queryKey: meldKeys.activities(meld.id)
  });
};
const useCreateReminder = (meld) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationKey: meldKeys.mutations.createReminder(meld.id.toString()),
    mutationFn: (data) => {
      return apiPost(LinkHelper.normalize(ApiUrls.reminderList), __spreadProps(__spreadValues({}, data), {
        meld: meld.id
      }));
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.reminderCreateSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.reminderCreateFailure,
        color: "danger"
      });
    }
  });
};
const useUpdateReminder = (meld) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationKey: meldKeys.mutations.editReminder(meld.id.toString()),
    mutationFn: (data) => {
      return apiPatch(LinkHelper.normalize(ApiUrls.reminderDetail(data.id)), __spreadProps(__spreadValues({}, data), {
        meld: meld.id
      }));
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.reminderUpdateSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.reminderUpdateFailure,
        color: "danger"
      });
    }
  });
};
const useToggleCompleteMeldReminder = (meld) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (reminder) => apiPatch(LinkHelper.normalize(ApiUrls.reminderComplete(reminder)), {}),
    onSuccess: () => {
      addToast({
        text: toastMessages.reminderCompleteSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.reminderCompleteFailure,
        color: "danger"
      });
    }
  });
};
const useDeleteMeldReminder = (meld) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: (reminder) => apiDelete(LinkHelper.normalize(ApiUrls.reminderDetail(reminder.toString()))),
    onSuccess: () => {
      addToast({
        text: toastMessages.reminderDeleteSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.reminderDeleteFailure,
        color: "danger"
      });
    }
  });
};
const useDeleteMeldWorkLog = (meld) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: (workLogId) => apiDelete(LinkHelper.normalize(ApiUrls.workEntryDetail(workLogId))),
    onSuccess: () => {
      addToast({
        text: toastMessages.workLogDeleteSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.workLogDeleteFailure,
        color: "danger"
      });
    }
  });
};
const useBulkPatchUpdateMeldResidents = (meld) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  const url = LinkHelper.normalize(ApiUrls.meldTenants(meld.id.toString()));
  const addResidentsToMeld = useMutation({
    mutationFn: ({ selectedItems }) => {
      const residents = [...meld.tenants.map((item) => item.id), ...selectedItems.map((item) => item.id)];
      return apiPatch(url, {
        tenants: residents
      });
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.residentBulkUpdateSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries({ queryKey: meldKeys.detail(meld.id.toString()) });
    },
    onError: () => {
      addToast({
        text: toastMessages.residentBulkUpdateFailure,
        color: "danger"
      });
    }
  });
  const removeResidentsFromMeld = useMutation({
    mutationFn: ({ selectedItems }) => {
      const residents = meld.tenants.map((item) => item.id).filter((id) => !selectedItems.find((item) => id === item.id));
      return apiPatch(url, {
        tenants: residents
      });
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.residentBulkUpdateSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries({ queryKey: meldKeys.detail(meld.id.toString()) });
    },
    onError: () => {
      addToast({
        text: toastMessages.residentBulkUpdateFailure,
        color: "danger"
      });
    }
  });
  return { addResidentsToMeld, removeResidentsFromMeld };
};
const useSearchMeldTags = (query) => {
  return useQuery({
    queryFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.meldTagSearchList)}?query=${query}`),
    queryKey: meldKeys.searchTags(query)
  });
};
const useUpdateMeldTags = (meld) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (tags) => apiPatch(LinkHelper.normalize(ApiUrls.meldTags(meld.id)), {
      tags
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.updateMeldTagsSuccess,
        color: "success"
      });
      return queryClient.invalidateQueries(meldKeys.detail(meld.id.toString()));
    },
    onError: () => {
      addToast({
        text: toastMessages.updateMeldTagsFailure,
        color: "danger"
      });
    }
  });
};
const useCancelMeld = (meld) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (formData) => apiPatch(LinkHelper.normalize(ApiUrls.meldCancel(meld.id)), {
      manager_cancellation_reason: formData.reason || ""
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldCancelSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.detail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldCancelFailure,
        color: "danger"
      });
    }
  });
};
const useFinishMeld = (meld) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (formData) => apiPatch(LinkHelper.normalize(ApiUrls.completeMeld(meld.id)), formData),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldFinishSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.detail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldFinishFailure,
        color: "danger"
      });
    }
  });
};
const useGetManagementAgentWatching = (meld) => {
  const watching = useQuery({
    queryKey: meldKeys.managerWatch(meld.id),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldWatch(meld.id))),
    enabled: !!meld.id,
    // this query is pre-fetched on the meld details page, staleTime here prevents
    // refetching when the other components using this hook mount
    staleTime: 3e3
  });
  return watching;
};
const useManagementAgentWatch = (meld) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return apiPost(LinkHelper.normalize(ApiUrls.meldWatch(meld.id)), {});
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.managementAgentWatch,
        color: "success"
      });
      return Promise.allSettled([
        queryClient.invalidateQueries(meldKeys.managerWatch(meld.id.toString())),
        queryClient.invalidateQueries(meldKeys.managerWatchlist(meld.id.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.managementAgentWatchError,
        color: "danger"
      });
    }
  });
};
const useManagementAgentUnwatch = (meld) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return apiDelete(LinkHelper.normalize(ApiUrls.meldWatch(meld.id)));
    },
    onSuccess: () => {
      addToast({
        text: toastMessages.managementAgentUnwatch,
        color: "success"
      });
      return Promise.allSettled([
        queryClient.invalidateQueries(meldKeys.managerWatch(meld.id.toString())),
        queryClient.invalidateQueries(meldKeys.managerWatchlist(meld.id.toString()))
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.managementAgentUnwatchError,
        color: "danger"
      });
    }
  });
};
const useGetManagementAgentWatchlist = (meld) => {
  return useQuery({
    queryKey: meldKeys.managerWatchlist(meld.id),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldWatchlist(meld.id))),
    enabled: !!meld.id,
    // this query is pre-fetched on the meld details page, staleTime here prevents
    // refetching when the other components using this hook mount
    staleTime: 3e3
  });
};
const useGetAvailableAgentsWatchlist = (meld, queryParams) => {
  return useQuery({
    queryKey: meldKeys.managerAvailableWatchlist(meld.id, queryParams),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.meldWatchSearchAll), { params: queryParams })
  });
};
const useAddWatchersToWatchlist = (meld) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (management_agent_ids) => {
      return Promise.all(
        management_agent_ids.map((management_agent_id) => {
          return apiPost(LinkHelper.normalize(ApiUrls.meldWatchlist(meld.id)), { management_agent_id });
        })
      );
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(meldKeys.managerWatchlist(meld.id.toString()));
    },
    onError: () => {
    }
  });
};
const useDeleteWatchersFromWatchlist = (meld) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (management_agent_ids) => {
      return Promise.all(
        management_agent_ids.map((management_agent_id) => {
          return apiDelete(LinkHelper.normalize(ApiUrls.meldWatchlistDelete(meld.id, management_agent_id)));
        })
      );
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(meldKeys.managerWatchlist(meld.id.toString()));
    },
    onError: () => {
    }
  });
};
const useCalculateProximityRequest = (meldId) => {
  return useMutation(
    (proximityData) => apiPost(LinkHelper.normalize(ApiUrls.meldProximity(meldId)), proximityData),
    {
      onSuccess: () => {
      },
      onError: () => {
      }
    }
  );
};
const useMergeMelds = () => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation(
    (params) => {
      const { destinationId, sourceIds } = params;
      return apiPost(LinkHelper.normalize(ApiUrls.meldMerge(destinationId)), {
        destination_id: destinationId,
        source_ids: sourceIds
      });
    },
    {
      onSuccess: () => {
        addToast({
          text: toastMessages.meldsMergeSuccess,
          color: "success"
        });
        return Promise.all([
          queryClient.invalidateQueries(meldKeys.all),
          queryClient.resetQueries(calendarMeldKeys.all())
        ]);
      },
      onError: () => {
        addToast({
          text: toastMessages.meldsMergeFailure,
          color: "danger"
        });
      }
    }
  );
};
const useGetMergedMeldDetails = (mergedMeldId) => {
  return useQuery({
    queryKey: meldKeys.mergedMeld(mergedMeldId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.mergedMeldDetail(mergedMeldId)))
  });
};
const useCancelAppointment = (meld, additionalOnSuccess) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (formData) => apiPatch(LinkHelper.normalize(ApiUrls.managementAppointmentCancel(formData.appointmentId)), {
      manager_cancellation_reason: formData.reason || ""
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.appointmentCancelSuccess,
        color: "success"
      });
      additionalOnSuccess == null ? void 0 : additionalOnSuccess();
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.detail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.managerScheduledEventsAll())
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.appointmentCancelFailure,
        color: "danger"
      });
    }
  });
};
const useAgentCancelVendorAppointment = (meld, additionalOnSuccess) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (formData) => apiPatch(LinkHelper.normalize(ApiUrls.managementCancelVendorAppointment(formData.appointmentId)), {
      manager_cancellation_reason: formData.reason || ""
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.appointmentCancelSuccess,
        color: "success"
      });
      additionalOnSuccess == null ? void 0 : additionalOnSuccess();
      return Promise.all([
        queryClient.invalidateQueries(meldKeys.detail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
        queryClient.resetQueries(calendarMeldKeys.vendorScheduledEventsAll())
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.appointmentCancelFailure,
        color: "danger"
      });
    }
  });
};
export {
  useGetPropertyLevelMelds,
  useGetPropertyUnitLevelMelds,
  useGetUnitMelds,
  useGetVendorMelds,
  useGetMeldNexusesByRange,
  useGetResidentMelds,
  useGetMeldDetailsQuery,
  usePatchMeldDetails,
  usePatchMeldDetailsNotes,
  useGetRecurringMeldMelds,
  usePatchOwnerApprovalStatus,
  usePatchOwnerApprovalAcknowledge,
  useGetMeldEstimates,
  useGetMeldEstimatesWithItemTotals,
  useRequestMeldEstimate,
  useBulkRequestOwnerApprovalMeldEstimate,
  usePatchAssignMeldMaintenance,
  useGetMeldActivities,
  useCreateReminder,
  useUpdateReminder,
  useToggleCompleteMeldReminder,
  useDeleteMeldReminder,
  useGetWorkEntriesMelds,
  useGetOpenWorkEntries,
  useGetWorkEntryDetail,
  useCreateWorkEntry,
  usePatchWorkEntry,
  useDeleteMeldWorkLog,
  useGetMeldResidentDetails,
  usePatchCoordinator,
  useSearchMeldTags,
  useUpdateMeldTags,
  useBulkPatchUpdateMeldResidents,
  useCancelMeld,
  useFinishMeld,
  useGetManagementAgentWatching,
  useManagementAgentWatch,
  useManagementAgentUnwatch,
  useGetManagementAgentWatchlist,
  useGetAvailableAgentsWatchlist,
  useAddWatchersToWatchlist,
  useDeleteWatchersFromWatchlist,
  useCalculateProximityRequest,
  useMergeMelds,
  useGetMergedMeldDetails,
  useCancelAppointment,
  useAgentCancelVendorAppointment,
  meldKeys
};
