import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { toastMessages, useAddToast } from "@pm-frontend/shared/store/toast";
import { apiFetch, apiPatch, apiPost, apiPut } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { meldKeys } from "@pm-frontend/routes/Melds/queries";
import { BaseLineItemSerializer } from "@pm-frontend/shared/types/api/estimates/serializers/base_serializers";
import { ToggleState } from "@pm-frontend/shared/hooks/useOpenCloseState";
import { AppSettingsDetailViewSerializer } from "@pm-frontend/shared/types/api/settings/serializers/app_settings_detail_view";
import { CreateUpdateEstimateForm, LineItemForm } from "../Melds/shared/types/CreateUpdateEstimateForm";
import { SelectedFilesByType } from "@pm-frontend/shared/utils/file-utils";

const estimateKeys = {
  all: ["estimates"],
  create: (meld_id: string | number) => [...estimateKeys.all, "meld", meld_id.toString()],
  detail: (id: string | number) => [...estimateKeys.all, id.toString()],
  lineItems: (id: string | number) => [...estimateKeys.detail(id), "line-items"],
  markupDefaults: () => [...estimateKeys.all, "line-items", "markup", "defaults"],
} as const;

const useCreateEstimate = (meld_id: number, flyoutToggle?: ToggleState) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  const lineitemMutation = useCreateEstimateLineItems();
  return useMutation({
    mutationFn: (data: CreateUpdateEstimateForm) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { lineitems, ...estimate } = data;
      return apiPost(LinkHelper.normalize(ApiUrls.meldEstimates(meld_id)), estimate);
    },
    onSuccess: async (data, variables) => {
      const lineItemArgs = variables.lineitems.map((lineitem) => ({ estimate_id: data.id, data: lineitem }));
      await Promise.all(lineItemArgs.map((args) => lineitemMutation.mutate(args)));

      if (flyoutToggle) {
        flyoutToggle.close();
      }
      queryClient.invalidateQueries({ queryKey: meldKeys.detail(meld_id) });

      addToast({
        text: toastMessages.estimateCreationSuccess,
        color: "success",
      });
    },
    onError: () => {
      addToast({
        text: toastMessages.estimateCreationFailure,
        color: "danger",
      });
    },
  });
};

const useCreateEstimateLineItems = () => {
  const addToast = useAddToast();
  return useMutation({
    mutationFn: ({ estimate_id, data }: { estimate_id: number; data: LineItemForm }) =>
      apiPost(LinkHelper.normalize(ApiUrls.estimateLineItems(estimate_id)), { ...data, cost_estimate: estimate_id }),
    onError: () => {
      addToast({
        text: toastMessages.estimateLineItemCreationFailure,
        color: "danger",
      });
    },
  });
};

const useUpdateEstimate = (estimateId: number) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  return useMutation({
    mutationFn: (data: CreateUpdateEstimateForm) => {
      const { lineitems, ...otherData } = data;
      const formData = {
        ...otherData,
        line_items: lineitems.map((item) => ("cost_estimate" in item ? item : { ...item, cost_estimate: estimateId })),
      };
      return apiPut(LinkHelper.normalize(ApiUrls.meldEstimatesV2(estimateId)), formData);
    },
    onSuccess: async (data) => {
      queryClient.invalidateQueries({ queryKey: meldKeys.detail(data.original_meld) });
      queryClient.invalidateQueries({ queryKey: estimateKeys.detail(estimateId) });
      queryClient.invalidateQueries({ queryKey: estimateKeys.lineItems(estimateId) });

      addToast({
        text: toastMessages.estimateUpdateSuccess,
        color: "success",
      });
    },
    onError: () => {
      addToast({
        text: toastMessages.estimateUpdateFailure,
        color: "danger",
      });
    },
  });
};

const useUpdateEstimateLineItems = (estimateId: number) => {
  const addToast = useAddToast();
  return useMutation({
    mutationFn: ({ data }: { data: BaseLineItemSerializer }) => {
      if (data.id) {
        const { id, ...lineitem } = data;
        return apiPut(LinkHelper.normalize(ApiUrls.estimateLineItem(id)), lineitem);
      } else {
        return apiPost(LinkHelper.normalize(ApiUrls.estimateLineItems(estimateId)), data);
      }
    },
    onError: () => {
      addToast({
        text: toastMessages.estimateLineItemUpdateFailure,
        color: "danger",
      });
    },
  });
};

const useGetEstimateLineItems = (estimateId: number) => {
  return useQuery<BaseLineItemSerializer[]>({
    queryKey: estimateKeys.lineItems(estimateId),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.estimateLineItems(estimateId))),
  });
};

const useApproveMeldEstimate = (estimateId: number, meldId: number) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationFn: () =>
      apiPatch(LinkHelper.normalize(ApiUrls.estimateDetail(estimateId.toString())), {
        estimate_status: "ESTIMATE_APPROVED",
      }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldApproveEstimateSuccess,
        color: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString())),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldApproveEstimateFailure,
        color: "danger",
      });
    },
  });
};

const useCancelMeldEstimate = (estimateId: number, meldId: number) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationFn: () =>
      apiPatch(LinkHelper.normalize(ApiUrls.estimateDetail(estimateId.toString())), {
        estimate_status: "ESTIMATE_CLOSED",
      }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldCloseEstimateSuccess,
        color: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString())),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldCloseEstimateFailure,
        color: "danger",
      });
    },
  });
};
const useBulkRequestOwnerApprovalMeldEstimate = (estimateId: number, meldId: number) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  const defaultSelectedFiles = {
    managerFiles: [],
    vendorFiles: [],
    tenantFiles: [],
  };

  return useMutation({
    mutationFn: ({
      showLineItems,
      files = defaultSelectedFiles,
      excludedColumns = [],
    }: {
      showLineItems: boolean;
      files: SelectedFilesByType;
      excludedColumns: string[];
    }) =>
      apiPost(LinkHelper.normalize(ApiUrls.estimateRequestOwnerApproval), {
        approvalEstimates: [{ id: estimateId }],
        showLineItems,
        excludedHeaders: excludedColumns.join(","),
        ...files,
      }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateSuccess,
        color: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString())),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateFailure,
        color: "danger",
      });
    },
  });
};

const useRequestOwnerApprovalMeldEstimate = (estimateId: number, meldId: number) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationFn: (showLineItems: boolean) =>
      apiPost(LinkHelper.normalize(ApiUrls.estimateRequestOwnerApproval), {
        approvalEstimates: [{ id: estimateId }],
        showLineItems,
      }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateSuccess,
        color: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString())),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldRequestOwnerApprovalEstimateFailure,
        color: "danger",
      });
    },
  });
};

const useCancelRequestOwnerApprovalMeldEstimate = (estimateId: number, meldId: number) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();

  return useMutation({
    mutationFn: () =>
      apiPatch(LinkHelper.normalize(ApiUrls.estimateDetail(estimateId)), {
        estimate_status: "ESTIMATE_SUBMITTED",
      }),
    onSuccess: () => {
      addToast({
        text: toastMessages.meldCancelRequestOwnerApprovalEstimateSuccess,
        color: "success",
      });
      return Promise.all([
        queryClient.invalidateQueries(estimateKeys.detail(estimateId.toString())),
        queryClient.invalidateQueries(meldKeys.detail(meldId.toString())),
      ]);
    },
    onError: () => {
      addToast({
        text: toastMessages.meldCancelRequestOwnerApprovalEstimateFailure,
        color: "danger",
      });
    },
  });
};

const useGetMarkupDefaults = () => {
  const query = useQuery<AppSettingsDetailViewSerializer>({
    queryKey: estimateKeys.markupDefaults(),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.applicationSettings)),
    cacheTime: 0,
    staleTime: 0,
  });

  return {
    ...query,
    default_markup: query.data?.default_estimate_line_item_markup,
    default_markup_type: query.data?.default_estimate_line_item_markup_metric,
  };
};

const useGetEstimatePDFPreview = (
  estimateId: number,
  excludedHeaders: string[],
  hideLineItems: boolean,
  managerFiles: number[],
  tenantFiles: number[],
  vendorFiles: number[]
) => {
  return useQuery({
    queryFn: () =>
      apiPost(LinkHelper.normalize(ApiUrls.estimatePDFPreview(estimateId)), {
        excluded_headers: excludedHeaders.join(","),
        show_line_items: !hideLineItems,
        manager_files: managerFiles,
        tenant_files: tenantFiles,
        vendor_files: vendorFiles,
      }),
  });
};

export {
  useApproveMeldEstimate,
  useCancelMeldEstimate,
  useCreateEstimate,
  useRequestOwnerApprovalMeldEstimate,
  useCancelRequestOwnerApprovalMeldEstimate,
  useGetEstimateLineItems,
  useGetMarkupDefaults,
  useUpdateEstimate,
  useUpdateEstimateLineItems,
  useBulkRequestOwnerApprovalMeldEstimate,
  useGetEstimatePDFPreview,
  estimateKeys,
};
